import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import {resetRouter} from '../router/initRoutes'
import country from '../util/json/city.json'
import routerData from '../router/index.json'
import HF from '../util/myApi'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    Home:{
      NumChange:true,
      EChartsFlag:true
    },
    regionData:country,//静态省市区数据
    loginInfo:null,
    userRouter:null,
    user:null,
    webIM:null,
    webIMMsgHistoryNum:10,
    justLogin:false,//是否是刚登录状态，用于组件Header提示用户完善个人信息功能
    codeTime:0,//验证码倒计时
  },
  mutations: {
    codeTimeHandel(state,v){
      state.codeTime=v
      let intTime = setInterval(()=>{
        state.codeTime--
        if(!state.codeTime){
          return clearInterval(intTime)
        }
      },1000)

    },
    //刚刚登录
    justLoginState(state){
      state.justLogin=true
    },
    //退出登录
    tokenTimeOut(state){
      resetRouter()//重置路由信息--清除旧路由
      // WebIM.conn.close()//退出环信登录
      state.loginInfo=''
      state.user=''
      state.webIM=''
      state.userRouter=null
      localStorage.removeItem('user')
      localStorage.removeItem('webIM')
      localStorage.removeItem('paas_loginInfo')
      sessionStorage.removeItem('PAAS_TOKEN')
      HF.setCookie('PAAS_ACTSAVE','',0)
      localStorage.removeItem('userTokenTime')
      localStorage.removeItem('PAAS_AllRouter')
      localStorage.removeItem('paas_dict')
      localStorage.removeItem('postClass')
      localStorage.removeItem('paas_companyList')
      router.replace('/login')
    },
    changeEChartsFlag(state){
      state.Home.EChartsFlag=!state.Home.EChartsFlag
    },
    upRegionData(state,data){
      state.regionData=data
    },
    webIMSave(state,data){
      state.webIM = data
    },
    webIMClear(state){
      state.webIM = null
    },
    loginInfoSave(state,data){
      state.loginInfo=data
      // let allRoutes = data.auth
      let allRoutes = routerData
      // console.log('静态路由',allRoutes)
      let arr = []
      if(allRoutes){
        allRoutes.forEach(item=>{
          let a = {
            path:item.path,
            name: item.name,
            component:resolve => require(['../views'+item.component], resolve),
            meta:item.meta?item.meta:{},
            children:[]
          }
          if(item.meta.redirect){
            a.redirect=item.meta.redirect
          }
          arr.push(a)
        })
        allRoutes.forEach((item,i)=>{
          if(item.children&&item.children.length){
            item.children.forEach(vitem=>{
              let a = {
                path:vitem.path,
                name: vitem.name,
                component:resolve => require(['../views'+vitem.component], resolve),
                meta:vitem.meta?vitem.meta:{},
                children:[]
              }
              if(vitem.meta.redirect){
                a.redirect=vitem.meta.redirect
              }
              arr[i].children.push(a)
            })
          }
        })
        allRoutes.forEach((item,i)=>{
          if(item.children&&item.children.length){
            item.children.forEach((vitem,vi)=>{
              if(vitem.children&&vitem.children.length){
                vitem.children.forEach(witem=>{
                  let a = {
                    path:witem.path,
                    name: witem.name,
                    component:resolve => require(['../views'+witem.component], resolve),
                    meta:witem.meta?witem.meta:{},
                    children:[]
                  }
                  arr[i].children[vi].children.push(a)
                })
              }
            })
          }
        })
        state.userRouter=arr
        arr.forEach(item=>{
          router.addRoute(item.name,item)
        })
        localStorage.setItem('PAAS_AllRouter',JSON.stringify(allRoutes)) // vuex完成动态路由添加 标记
      }
    },
    loginInfoClear(state){
      state.loginInfo=null
    },
    userSave(state,data){
      state.user=data
    },
    userClear(state){
      state.user=null
    },
  },
  actions: {

  },
  modules: {},
});
